import React from "react";
import ReactGA from "react-ga4";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "./views/auth/pages/login/page";
import { APP_THEME } from "./theme";
import AuthGuard from "./views/account/guards/AuthGuard";
import { Provider } from "mobx-react";
import GlobalStore from "./stores/GlobalStore";
import ModelDetailsPageStore from "./stores/ModelDetailsPageStore";
import VideoUploadStore from "./stores/VideoUploadStore";
import SessionViewerPageStore from "./stores/SessionViewerPageStore";
import { STORE_NAMES, TUTORIAL_PAGES } from "./const/global";
import {
  APPLICATION_ROUTES,
  AUTH_ROUTES,
  IFRAME_ROUTES,
  INFORMATION_ROUTES,
  INTERIOR_AI_ROUTES,
  MODEL_ROUTES,
  PROFILE_ROUTE_BASE,
  PUBLIC_MODEL_ROUTES,
  SESSION_ROUTES,
  THREE_D_AI_ROUTES,
  USER_ROUTES,
} from "./views/routes";
import ResetPasswordPage from "./views/auth/pages/reset-password/page";
import ModelDetailsPage from "./views/models/pages/ModelDetailsPage";
import OrganizationHomePage from "./views/profile/pages/ProfilePage";
import PublicModelDetailsPage from "./views/models/pages/PublicModelDetailsPage";
import RegisterPage from "./views/auth/pages/register/page";
import SharedModelDetailsPage from "./views/models/pages/SharedModelDetailsPage";
import { getEnvVariables, isProduction } from "./env";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TutorialsListPage from "./views/information/pages/TutorialsListPage";
import VideoTutorialPage from "./views/information/pages/VideoTutorialPage";
import SessionsListPage from "./views/session/pages/SessionsListPage";
import SessionDetailsPage from "./views/session/pages/SessionDetailsPage";
import SessionViewerPage from "./views/session/pages/SessionViewerPage/SessionViewerPage";
import SessionCreatePage from "./views/session/pages/SessionCreatePage";
import OrganizationStore from "./stores/OrganizationStore";
import RoutePermissionGuard from "./views/account/guards/RoutePermissionGuard";
import { ServiceType } from "./models/ServicePlan";
import ViewerStore from "./stores/ViewerStore";
import UserDeletePage from "./views/users/pages/UserDeletePage";
import UserAccountDeletedPage from "./views/users/pages/UserAccountDeletedPage";
import ModelsListPage from "./views/models/pages/ModelListPage.tsx/ModelsListPage";
import AuthorizedPageLayout from "./views/shared/layouts/AuthorizedPageLayout";
import ScrollToTopHook from "./views/shared/hook/scrollToTopHook";
import "./styles.css";
import i18n from "./i18n";
import useImportScript from "./utils/importScript";
import SessionStreamStore from "./stores/SessionStreamStore";
import { GlobalEventEmitterProvider } from "./views/shared/context/GlobalEventEmitter";
import { UserContextProvider } from "./views/account/context/user.context";
import InteriorAiPage from "./views/interior-ai/pages/interior-ai";
import RegisterTokenFinishPage from "./views/auth/pages/register-token-finish/page";
import RegisterTokenInitPage from "./views/auth/pages/register-token-init/page";
import RegisterTokenInitIframePage from "./views/auth/pages/register-token-init/iframe-page";
import AccountDeleteInProgressPage from "./views/users/pages/UserAccountDeleteInProgressPage";
import RegisterWithCouponCodePage from "./views/auth/pages/register-with-coupon";
import { UserMetadataContextProvider } from "./views/account/context/user-metadata.context";
import ThreeDAI from "./views/threed-ai/pages/threed-ai";
import EmbeddedDetailsPage from "./views/models/pages/PublicEmbeddedDetailsPage";

if (isProduction()) {
  ReactGA.initialize(getEnvVariables().GOOGLE_ANALYTIC_API_KEY);
  ReactGA._gaCommandSendPageview(
    window.location.pathname + window.location.search,
    {}
  );
}

const stores = {
  [STORE_NAMES.GlobalStore]: GlobalStore,
  [STORE_NAMES.ViewerStore]: ViewerStore,
  [STORE_NAMES.VideoUploadStore]: VideoUploadStore,
  [STORE_NAMES.SessionViewerPageStore]: SessionViewerPageStore,
  [STORE_NAMES.ModelDetailsPageStore]: ModelDetailsPageStore,
  [STORE_NAMES.OrganizationStore]: OrganizationStore,
  [STORE_NAMES.SessionStreamStore]: SessionStreamStore,
};
const Router: any = BrowserRouter;

const SESSION_ROUTES_COMPONENTS = [
  { path: SESSION_ROUTES.sessionViewer, component: SessionViewerPage },
  { path: SESSION_ROUTES.sessionList, component: SessionsListPage },
  { path: SESSION_ROUTES.sessionCreate, component: SessionCreatePage },
  { path: SESSION_ROUTES.sessionViewer, component: SessionViewerPage },
  { path: SESSION_ROUTES.sessionDetails, component: SessionDetailsPage },
  { path: SESSION_ROUTES.home, component: SessionsListPage },
];

const getSessionRoutes = () => {
  return SESSION_ROUTES_COMPONENTS.map((sessionRoute) => {
    const component = (props: any) => (
      <RoutePermissionGuard servicePlan={ServiceType.Tacbrowse}>
        <sessionRoute.component {...props} />
      </RoutePermissionGuard>
    );

    return (
      <Route
        key={sessionRoute.path}
        path={sessionRoute.path}
        component={component}
      />
    );
  });
};

const AuthDeleteAccountPage = () => (
  <AuthGuard>
    <UserDeletePage />
  </AuthGuard>
);

const ApplicationModule = () => (
  <AuthGuard>
    <AuthorizedPageLayout>
      <Switch>
        <Route path={MODEL_ROUTES.list} component={ModelsListPage} />
        <Route
          path={USER_ROUTES.accountDeleted}
          component={UserAccountDeletedPage}
          exact
        />
        <Route
          path={USER_ROUTES.delete}
          component={AuthDeleteAccountPage}
          exact
        />
        <Route
          path={MODEL_ROUTES.sharedModelDetails}
          component={SharedModelDetailsPage}
        />
        <Route path={MODEL_ROUTES.details} component={ModelDetailsPage} />
        <Route
          path={`/${PROFILE_ROUTE_BASE}`}
          component={OrganizationHomePage}
        />
        {TUTORIAL_PAGES.map((page) => (
          <Route
            key={page.route}
            path={page.route}
            component={VideoTutorialPage}
          />
        ))}
        {getSessionRoutes()}
        <Route path={INFORMATION_ROUTES.home} component={TutorialsListPage} />
        <Route
          path={INTERIOR_AI_ROUTES.home}
          component={InteriorAiPage}
          exact
        />
        <Route path={THREE_D_AI_ROUTES.home} component={ThreeDAI} exact />
        <Route
          path="/"
          render={() => (
            <Redirect
              to={APPLICATION_ROUTES.model.replace(":page", String(1))}
            />
          )}
        />
      </Switch>
    </AuthorizedPageLayout>
  </AuthGuard>
);

const App = () => {
  useImportScript("https://js.stripe.com/v3/pricing-table.js");

  return (
    <I18nextProvider i18n={i18n}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap"
        rel="stylesheet"
      ></link>
      <Router>
        <Provider {...stores}>
          <GlobalEventEmitterProvider>
            <UserContextProvider>
              <UserMetadataContextProvider>
                <StyledThemeProvider theme={APP_THEME}>
                  <ToastContainer
                    position="bottom-right"
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                  />
                  <ScrollToTopHook />
                  <Switch>
                    <Route path={AUTH_ROUTES.login} component={Login} exact />
                    <Route
                      path={AUTH_ROUTES.register}
                      component={RegisterTokenInitPage}
                      exact
                    />
                    <Route
                      path={AUTH_ROUTES.registerWithCoupon}
                      component={RegisterWithCouponCodePage}
                      exact
                    />
                    <Route
                      path={AUTH_ROUTES.register}
                      component={RegisterTokenInitPage}
                      exact
                    />
                    <Route
                      path={AUTH_ROUTES.passwordReset}
                      component={ResetPasswordPage}
                      exact
                    />
                    <Route
                      path={AUTH_ROUTES.initTokenRegistration}
                      component={RegisterTokenInitPage}
                      exact
                    />
                    <Route
                      path={AUTH_ROUTES.fullFormRegister}
                      component={RegisterPage}
                      exact
                    />
                    <Route
                      path={AUTH_ROUTES.finishTokenRegistration}
                      component={RegisterTokenFinishPage}
                      exact
                    />
                    <Route
                      path={IFRAME_ROUTES.iframeInitTokenRegistration}
                      component={RegisterTokenInitIframePage}
                      exact
                    />
                    <Route
                      path={USER_ROUTES.deleteInProgress}
                      component={AccountDeleteInProgressPage}
                      exact
                    />
                    <Route
                      path={PUBLIC_MODEL_ROUTES.details}
                      component={PublicModelDetailsPage}
                      exact
                    />
                    <Route
                      path={PUBLIC_MODEL_ROUTES.embeddedDetails}
                      component={EmbeddedDetailsPage}
                    />
                    <Route path="/" component={ApplicationModule} />
                  </Switch>
                </StyledThemeProvider>
              </UserMetadataContextProvider>
            </UserContextProvider>
          </GlobalEventEmitterProvider>
        </Provider>
      </Router>
    </I18nextProvider>
  );
};

export default App;
