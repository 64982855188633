import { MessageService, ToastMessage } from "../../../services/MessageService";

const useToast = () => {
  const success = (message: string) => {
    MessageService.success(message);
  };

  const info = (message: string) => {
    MessageService.info(message);
  };

  const error = (message: string) => {
    MessageService.error(message);
  };

  const warn = (message: string) => {
    MessageService.warn(message);
  };

  const show = (message: ToastMessage) => {
    MessageService.show(message);
  };

  return { success, info, error, warn, show };
};

export default useToast;
