import { TFunction } from 'i18next';

export const EMAIL_PATTERN: RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const URL_PATTER: RegExp =
  /^(?:https?:\/\/)([\w.-]+(?:\.[\w.-]+)*|localhost(?::\d+)?)(\/[\w\-._~:/?#[\]@!$&'()*+,;=.]*)?$/;
export const PASSWORD_PATTERN: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
export const PHONE_PATTERN: RegExp = /^\+?([0-9]*-?\s?\(?\)?)+$/;
export const ZIP_CODE_PATTERN: RegExp = /^([0-9]*-*\s*?)+$/;
export const NUMBER_PATTERN: RegExp = /^([0-9])+$/;

type validationResult = string | undefined;

export type validatorFunctionType = (value: string, t: TFunction) => validationResult;

export const isEmail = (value: string, t: TFunction): validationResult => (
  new RegExp(EMAIL_PATTERN).test(value) ? undefined : t('validateErrorEmail')
);

export const isNotRequiredEmail = (value: string): validationResult => {
  const rule = !value || new RegExp(EMAIL_PATTERN).test(value);
  return rule ? undefined : 'validateErrorEmailOrEmpty';
};

export const isNotRequiredUrl = (value: string): validationResult => {
  const rule = !value || new RegExp(URL_PATTER).test(value);
  return rule ? undefined : 'validationErrUrl';
};

export const isUrl = (value: string): validationResult => (
  new RegExp(URL_PATTER).test(value) ? undefined : 'validationErrUrl'
);

export const isPassword = (value: string): validationResult => (
  new RegExp(PASSWORD_PATTERN).test(value) ? undefined : 'validationErrPassword'
);

export const isPhoneNumber = (value: string): validationResult => (
  new RegExp(PHONE_PATTERN).test(value) ? undefined : 'validationErrPhone'
);

export const isNotRequiredPhoneNumber = (value: string): validationResult => {
  const rule = !value || new RegExp(PHONE_PATTERN).test(value);
  return rule ? undefined : 'validationErrPhone';
};

export const isZipCode = (value: string): validationResult => (
  new RegExp(ZIP_CODE_PATTERN).test(value) ? undefined : 'validationErrZipCode'
);

export const minLength = (minLenght: number) => (value: string): validationResult => (
  (!!value && value.length >= minLenght)
    ? undefined
    : 'validationErrMinLenght'
);

export const maxLength = (maxLenght: number) => (value: string): validationResult => (
  (!value || (value && value.length < maxLenght)) ? undefined : 'validationErrMaxLenght'
);

export const isRequired = (value: any): validationResult => (
  value ? undefined : 'validationErrRequired'
);

export const isNumber = (value: any): validationResult => (
  new RegExp(NUMBER_PATTERN).test(value) ? undefined : 'validationErrNumber'
);
