import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../../shared/components/Dialog/Dialog";
import {
  IModelIssueForm,
  IModelIssueReq,
  ModelIssueType,
} from "../model/Issue";
import ReportIssueForm from "./ReportIssueForm";
import useToast from "../../../shared/hook/useToast";

export interface IReportIssueProps {
  model: {
    id: string;
  };
  loading: boolean;
  handleReport: (issue: IModelIssueReq) => Promise<any>;
}

interface IReportIssueModalContainerPropTypes extends IReportIssueProps {
  open: boolean;
  handleReport: (issue: IModelIssueReq) => Promise<any>;
  onClose: () => void;
}

const INITIAL_FORM: IModelIssueForm = {
  description: "",
  reason: null,
  platform: null,
  shareWithSupport: false,
};

const ReportIssueModalContainer = ({
  open,
  onClose,
  handleReport,
  loading,
}: IReportIssueModalContainerPropTypes) => {
  const { t } = useTranslation();
  const [form, setForm] = useState(INITIAL_FORM);
  const [valid, setValid] = useState(false);
  const toast = useToast();
  const handleFormChange = (newForm: IModelIssueForm, valid: boolean) => {
    setForm(newForm);
    setValid(valid);
  };

  const serializedFormReq = (): IModelIssueReq => {
    return {
      ...form,
      reason: form.reason || ModelIssueType.Other,
      userAgent: navigator.userAgent,
    };
  };

  const report = async () => {
    if (!valid) return;

    const reqForm = serializedFormReq();
    await handleReport(reqForm);

    toast.success("Your issue has been reported");

    onClose();
  };

  useEffect(() => {
    // Reseting form on close
    if (!open) {
      setForm(INITIAL_FORM);
    }
  }, [open]);

  return (
    <Dialog
      title={t("reportIssue")}
      open={open}
      onClose={onClose}
      confirmButtonProps={{
        disabled: !valid,
        onClick: report,
        loading,
      }}
    >
      <ReportIssueForm currentForm={form} onChange={handleFormChange} />
    </Dialog>
  );
};

export default ReportIssueModalContainer;
