import React, { useContext } from "react";
import DesktopAuthorizedNavbar from "./desktop/authorized";
import DesktopNonAuthorizedNavbar from "./desktop/non-authorized";
import MobileAuthorizedNavbar from "./mobile/authorized";
import MobileNonAuthorizedNavbar from "./mobile/non-authorized";
import { UserContext } from "../../../account/context/user.context";

const PublicNavbar = () => {
  const { user, loaded, isLogged } = useContext(UserContext);

  if (!loaded) {
    return null;
  }

  return (
    <>
      <div className="hidden lg:block w-full">
        {isLogged ? (
          <DesktopAuthorizedNavbar />
        ) : (
          <DesktopNonAuthorizedNavbar />
        )}
      </div>
      <div className="lg:hidden w-full">
        {isLogged ? <MobileAuthorizedNavbar /> : <MobileNonAuthorizedNavbar />}
      </div>
    </>
  );
};

export default PublicNavbar;
