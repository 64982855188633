import { createContext, useState } from "react";
import { LoggedUser } from "../../../models/User";

interface IUserContext {
  user: LoggedUser | null | undefined;
  setUser: (user: LoggedUser) => void;
  loaded: boolean;
  isLogged: boolean;
}

export const UserContext = createContext<IUserContext>({
  user: null,
  setUser: () => {},
  loaded: false,
  isLogged: false,
});

export const UserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // On this level we can ensure token exists - should be used after auth guard
  const [user, setUser] = useState<LoggedUser | null>(null);

  return (
    <UserContext.Provider
      value={{
        user: user as LoggedUser,
        setUser,
        loaded: typeof user !== "undefined",
        isLogged: !!user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
