import { useState } from "react";
import CircleLoader from "../../../../shared/components/CircleLoader";
import Dialog from "../../../../shared/components/Dialog/Dialog";
import DropZone from "../../../../shared/components/DropZone";
import ModelAssetService from "../../../services/ModelAssetService";
import { useModelGallery } from "../useGallery";
import useToast from "../../../../shared/hook/useToast";

const UploadImageDialog = () => {
  const [isUploading, setIsUploading] = useState(false);
  const {
    uploadDialogOpen,
    setUploadDialogOpen,
    model,
    setImages,
    setTooltipOpen,
  } = useModelGallery();
  const toast = useToast();

  const handleDrop = (files: File[]) => {
    const validFiles = [];
    const invalidFiles = [];

    for (const file of files) {
      if (file.type.startsWith("image/")) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    }

    uploadImages(validFiles);

    if (invalidFiles.length > 0) {
      toast.error(
        `Some files (${invalidFiles.length}) could not be uploaded because they are not images`
      );
    }
  };

  const uploadImages = async (files: File[]) => {
    if (!model) {
      console.error("No model found");
      return;
    }

    setIsUploading(true);

    try {
      for (const file of files) {
        await ModelAssetService.addModelAsset(model.id, file);
        toast.success(`${file.name} uploaded successfully`);
      }
      const assets = await ModelAssetService.getModelAssets(model.id);
      setImages(
        assets.data.map((asset) => ({
          id: asset.id,
          title: asset.originalName,
          src: asset.url,
          alt: asset.originalName,
        }))
      );
      setUploadDialogOpen(false);
      setTooltipOpen(true);
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Dialog
      open={uploadDialogOpen}
      onClose={() => {
        setUploadDialogOpen(false);
        setTooltipOpen(false);
      }}
      title="Upload Images"
      hideCancelButton
      hideConfirmButton
    >
      {!isUploading && <DropZone onDrop={handleDrop} />}
      {isUploading && (
        <div className="min-h-[120px] flex flex-col justify-center items-center gap-y-6">
          <CircleLoader color="primary" size={40} />
          <p className="text-sm text-text-primary">Uploading...</p>
        </div>
      )}
    </Dialog>
  );
};

export default UploadImageDialog;
