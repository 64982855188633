import { createContext, useState } from "react";
import { IPublicModel } from "../../../../models/Model";

interface ModelDetailsPageContextProps {
  model: IPublicModel | null;
  setModel: (model: IPublicModel) => void;
}

export const EmbeddedModelDetailsPageContext =
  createContext<ModelDetailsPageContextProps>(null);

export const EmbeddedModelDetailsPageContextProvider = ({ children }: any) => {
  const [model, setModel] = useState<IPublicModel | null>(null);

  return (
    <EmbeddedModelDetailsPageContext.Provider
      value={{
        model,
        setModel,
      }}
    >
      {children}
    </EmbeddedModelDetailsPageContext.Provider>
  );
};
