import { useState } from "react";
import { IModelIssueReq } from "../issue/model/Issue";
import { IssueService } from "../issue/service/IssueService";

const useReportModel = () => {
  const [loading, setLoading] = useState(false);

  const reportModel = (model: { id: string }, issue: IModelIssueReq) => {
    setLoading(true);
    IssueService.reportModel(model.id, issue)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { loading, reportModel };
};

export default useReportModel;
