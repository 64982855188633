import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../../interfaces";
import SkyeTooltip, { ISkyeTooltipPropTypes } from '../SkyeTooltip';

interface IInformationTooltipPropTypes extends ISkyeTooltipPropTypes {
  children: any,
  content: any,
}

const TooltipContentContainer = styled.div`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border-radius: 8px;
  overflow: hidden;
`;

const InformationTooltip = ({
  children,
  content,
  ...otherProps
}: IInformationTooltipPropTypes) => {
  return (
    <SkyeTooltip
      overlayClassName="information-tooltip"
      placement="bottomLeft"
      mouseEnterDelay={1}
      title={(
        <TooltipContentContainer>
          {content}
        </TooltipContentContainer>
      )}
      {...otherProps}
    >
      {children}
    </SkyeTooltip>
  )
}

export default InformationTooltip;
