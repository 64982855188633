import Button, { IButtonPropTypes } from "../../../../shared/buttons/Button";
import UploadIcon from "../../../../shared/Icons/UploadIcon";
import { IModelGalleryImage } from "../../../containers/Gallery/model";
import NoImagesInfo, { INoImagesInfoProps } from "../NoImagesInfo";

interface TooltipContentProps {
  title: string;
  downloadAllButtonProps?: Partial<IButtonPropTypes>;
  uploadButtonProps?: Partial<IButtonPropTypes>;
  showGallery: boolean;
  showEmptyGallery?: boolean;
  images: IModelGalleryImage[];
  noImagesInfoProps?: INoImagesInfoProps;
  onImageClick: (image: IModelGalleryImage) => void;
}

const TooltipContent = ({
  title = "Case Gallery",
  downloadAllButtonProps,
  uploadButtonProps,
  showGallery = true,
  showEmptyGallery = false,
  images = [],
  noImagesInfoProps,
  onImageClick,
}: TooltipContentProps) => {
  return (
    <div className="tooltip-content w-[310px] flex flex-col justify-between gap-y-4 p-4 text-text-primary">
      <div className="w-full flex flex-col gap-y-4">
        <div className="title-section flex justify-between items-center">
          <h4 className="text-base font-medium">{title}</h4>
          {!!downloadAllButtonProps && (
            <Button type="transparent" size="small">
              Download all
            </Button>
          )}
        </div>
        {showGallery && (
          <div className="content-section max-h-[400px] overflow-y-auto">
            <ul className="gallery-container grid grid-cols-2 gap-x-2 gap-y-4 items-start justify-start flex-wrap">
              {images.map((image) => (
                <li key={image.id}>
                  <div className="w-full flex flex-col gap-y-2">
                    <button
                      onClick={() => onImageClick(image)}
                      className="gallery-small-image  h-[72px] flex justify-center items-center bg-primary-50 rounded-md overflow-hidden gallery-element hover:shadow-md transition-shadow duration-200"
                    >
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="h-full w-auto object-cover"
                      />
                    </button>
                    <div className="gallery-element-info text-xs font-medium text-text-primary">
                      {image.title.slice(0, 20)}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        {showEmptyGallery && <NoImagesInfo {...noImagesInfoProps} />}
      </div>
      {!!uploadButtonProps && (
        <div className="button-section">
          <Button
            startIcon={<UploadIcon />}
            fullSize
            {...uploadButtonProps}
          ></Button>
        </div>
      )}
    </div>
  );
};

export default TooltipContent;
