import React from 'react'
import { Tooltip } from "antd";

export interface ISkyeTooltipPropTypes {
  open?: boolean;
  children: any;
  title?: any;
  leaveDelay?: number;
  placement?: any;
  visible?: boolean;
  trigger?: any;
  overlayClassName?: string;
  mouseEnterDelay?: number;
  onVisibleChange?: (visible: boolean) => void;
}

const SkyeTooltip = ({
  placement = "bottom",
  ...otherProps
}: ISkyeTooltipPropTypes) => {
  return <Tooltip placement={placement} {...otherProps} />;
};

export default SkyeTooltip;
