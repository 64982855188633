import { useMemo } from "react";
import Button from "../../../../shared/buttons/Button";
import Dialog from "../../../../shared/components/Dialog/Dialog";
import ModelAssetService from "../../../services/ModelAssetService";
import { useModelGallery } from "../useGallery";
import useToast from "../../../../shared/hook/useToast";

const ModelGalleryPreviewDialog = () => {
  const {
    currentImage,
    setCurrentImage,
    setGalleryOpen,
    nextImage,
    previousImage,
    nextImageAvailable,
    previousImageAvailable,
    model,
    images,
    setImages,
    currentImageIndex,
    totalImages,
    setTooltipOpen,
  } = useModelGallery();
  const toast = useToast();

  const handleClose = () => {
    setCurrentImage(null);
    setGalleryOpen(false);
    setTooltipOpen(false);
  };

  const dialogTitle = useMemo(() => {
    return `${currentImageIndex + 1} of ${totalImages} - ${
      currentImage?.title
    }`;
  }, [currentImageIndex, totalImages, currentImage]);

  if (!currentImage) {
    return null;
  }

  const handleDelete = () => {
    ModelAssetService.deleteModelAsset(model.id, currentImage.id)
      .then(() => {
        const newImages = images.filter(
          (image) => image.id !== currentImage.id
        );
        setCurrentImage(newImages[0] || null);
        setImages(newImages);

        if (newImages.length === 0) {
          setGalleryOpen(false);
          setTooltipOpen(false);
        }

        toast.success("Image deleted");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error deleting image");
      });
  };

  return (
    <Dialog
      size="large"
      title={dialogTitle}
      open={true}
      onClose={handleClose}
      hideCancelButton
      hideConfirmButton
    >
      <div className="h-[500px] flex flex-col justify-center items-center h-full">
        <img
          src={currentImage.src}
          alt={currentImage.alt}
          className="h-full w-auto max-w-full object-contain"
        />
      </div>
      <div className="flex justify-between items-center mt-4">
        <div>
          <Button
            type="transparent"
            color="danger"
            size="small"
            onClick={handleDelete}
          >
            Delete Image
          </Button>
        </div>
        <div className="buttons-row flex justify-end gap-x-2">
          <Button
            type="transparent"
            size="small"
            disabled={!previousImageAvailable}
            onClick={previousImage}
          >
            Previous
          </Button>
          <Button
            type="transparent"
            size="small"
            disabled={!nextImageAvailable}
            onClick={nextImage}
          >
            Next
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModelGalleryPreviewDialog;
