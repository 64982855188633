import classNames from "classnames";
import { inject, observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IGlobalStore } from "../../../../../stores/GlobalStore";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { getModelListPageUrl } from "../../../../../utils/globalUtils";
import { IAppContext, ModelType } from "../../../../interfaces";
import {
  INFORMATION_ROUTES,
  INTERIOR_AI_ROUTES,
  SESSION_ROUTES,
  THREE_D_AI_ROUTES,
} from "../../../../routes";
import LinkButton from "../../../buttons/LinkButton";
import Box, { BoxAlignedCenter } from "../../Box";
import CloseIcon from "../../../Icons/CloseIcon";
import LogoutIcon from "../../../Icons/LogoutIcon";
import ModelIcon from "../../../Icons/ModelIcon";
import TacbrowseIcon from "../../../Icons/TacbrowseIcon";
import TutorialIcon from "../../../Icons/TutorialIcon";
import UploadIcon from "../../../Icons/UploadIcon";
import NavbarBase from "../components/NavbarBase";
import { SessionAccessPolicy } from "../../../../session/SessionAccessPolicy";
import Button from "../../../buttons/Button";
import { SubscriptionType } from "../../../../payments/payments.model";
import InteriorAIIcon from "../../../Icons/InteriorAIIcon";
import useCurrentUser from "../../../../account/hooks/useCurrentUser";
import useSubscription from "../../../../account/hooks/useSubscription";
import AuthService from "../../../../../services/AuthService";

interface IAuthorizedMobileHeaderPropTypes extends RouteComponentProps {
  GlobalStore?: IGlobalStore;
  showButtons?: any;
  topSection: any;
}

/**
 * Closed menu styles
 */
const Icon = styled.div`
  width: 32px;
  height: 32px;
`;
const HamburgerIcon = styled.div`
  border-radius: 3px;
  height: 2px;
  width: 22px;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  content: "";
`;
const Header = styled.div`
  display: block;

  ${breakpoint("xl")`
    display: none;
  `}
`;
const HeaderTopBar = styled.div``;
const HamburgerMenu = styled(Icon)`
  padding: 5px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;

/**
 * Open menu styles
 */
const OpenNavWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${(p: IAppContext) =>
    p.theme.skye.colors.mobileNavBackground};
  z-index: 8000;
  padding: 20px;
`;
const CloseIconWrapper = styled(BoxAlignedCenter)`
  position: absolute;
  right: 13px;
  top: 18px;
  width: 32px;
  height: 32px;
  cursor: pointer;

  svg {
    path {
      fill: #fff;
    }
  }
`;
const TopInfoSection = styled(Box)`
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid
    ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
`;
const NavButtonsSection = styled.div`
  .link-button {
    margin-bottom: 22px;
  }
`;
const NavButton = styled(LinkButton)``;
const NavButtonIcon = styled(Icon)`
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.average};

  &.fill {
    svg {
      path {
        fill: ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
      }
    }
  }

  &.stroke {
    svg {
      path {
        stroke: ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
      }
    }
  }
`;
const NavButtonLabel = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
`;
const LogoutWrapper = styled.div`
  margin-top: 80px;

  .link-button {
    color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
    font-size: 16px;
  }
`;

const MobileNavbar = ({
  GlobalStore,
  history,
  topSection,
  showButtons,
}: IAuthorizedMobileHeaderPropTypes) => {
  const { t } = useTranslation();
  const { user, setUser } = useCurrentUser();
  const subscription = useSubscription();
  const showTacbrowse = SessionAccessPolicy.hasSessionAccess(user);
  const toggleMovileNav = (open: boolean) => () => {
    GlobalStore.toggleMobileNav(open);
  };
  const redirectToModels = () =>
    history.push(getModelListPageUrl(ModelType.Owned));
  const redirectToTutorials = () => history.push(INFORMATION_ROUTES.home);
  const redirectToSessions = () => history.push(SESSION_ROUTES.home);
  const openUploadDialog = () => {
    GlobalStore.uploadDialogOpen = true;
  };
  const redirectToThreeDai = () => history.push(THREE_D_AI_ROUTES.home);
  const redirectToAI = () => history.push(INTERIOR_AI_ROUTES.home);
  const showInterior = [
    SubscriptionType.Lite,
    SubscriptionType.Premium,
  ].includes(subscription.type);
  const showThreeDai = showInterior;

  const BUTTONS = [
    {
      icon: UploadIcon,
      translation: "uploadVideo",
      handler: openUploadDialog,
    },
    { icon: ModelIcon, translation: "models", handler: redirectToModels },
    {
      icon: TutorialIcon,
      translation: "videoTutorials",
      handler: redirectToTutorials,
      iconFillMethod: "fill",
    },
    {
      icon: TacbrowseIcon,
      translation: "sessions",
      hide: !showTacbrowse,
      handler: redirectToSessions,
    },
    {
      icon: InteriorAIIcon,
      translation: "navbar.interiorAi",
      hide: !showInterior,
      handler: redirectToAI,
    },
    {
      icon: InteriorAIIcon,
      translation: "3D Ai",
      hide: !showThreeDai,
      handler: redirectToThreeDai,
    },
  ];

  const handleLogout = async () => {
    AuthService.signOut();
    setUser(null);
    history.replace("/login");
  };

  const withClose = (action: any) => () => {
    action();
    GlobalStore.toggleMobileNav(false);
  };

  return (
    <div className="w-full z-0 z-[1000]">
      <Header className="mobile-navbar">
        <HeaderTopBar>
          <NavbarBase
            rightSide={
              !!user && (
                <div className="flex items-center justify-end">
                  <Button
                    color="accent"
                    type="solid"
                    startIcon={<UploadIcon />}
                    className="mr-4"
                    onClick={openUploadDialog}
                  >
                    {t("upload")}
                  </Button>
                  <HamburgerMenu
                    id="hamburger-menu-button"
                    onClick={toggleMovileNav(true)}
                    className={classNames([
                      "hamburger-menu",
                      { open: GlobalStore.mobileNavOpen },
                    ])}
                  >
                    <HamburgerIcon />
                    <HamburgerIcon />
                    <HamburgerIcon />
                  </HamburgerMenu>
                </div>
              )
            }
          >
            {null}
          </NavbarBase>
        </HeaderTopBar>
        {GlobalStore.mobileNavOpen && (
          <OpenNavWrapper className="mobile-nav-overlay">
            <CloseIconWrapper onClick={toggleMovileNav(false)}>
              <CloseIcon />
            </CloseIconWrapper>
            <TopInfoSection className="mobile-nav-top-section">
              {topSection}
            </TopInfoSection>
            <NavButtonsSection>
              {showButtons &&
                BUTTONS.map(
                  (button) =>
                    !button.hide && (
                      <NavButton
                        key={button.translation}
                        onClick={withClose(button.handler)}
                      >
                        <NavButtonIcon
                          className={classNames([
                            button.iconFillMethod || "stroke",
                          ])}
                        >
                          {button.icon && <button.icon />}
                        </NavButtonIcon>
                        <NavButtonLabel>{t(button.translation)}</NavButtonLabel>
                      </NavButton>
                    )
                )}
              {!!user && (
                <LogoutWrapper>
                  <NavButton onClick={withClose(handleLogout)}>
                    <NavButtonIcon className="fill">
                      <LogoutIcon />
                    </NavButtonIcon>
                    <NavButtonLabel>{t("logout")}</NavButtonLabel>
                  </NavButton>
                </LogoutWrapper>
              )}
            </NavButtonsSection>
          </OpenNavWrapper>
        )}
      </Header>
    </div>
  );
};

export default inject("GlobalStore")(withRouter(observer(MobileNavbar)));
