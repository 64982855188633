import { useState } from "react";
import { IModelIssueReq } from "../issue/model/Issue";
import { IssueService } from "../issue/service/IssueService";

const useReportPublicModel = () => {
  const [loading, setLoading] = useState(false);

  const reportModel = (model: { publicKey: string }, issue: IModelIssueReq) => {
    setLoading(true);
    IssueService.reportPublicModel(model.publicKey, issue)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { loading, reportModel };
};

export default useReportPublicModel;
