import { createContext, useState } from "react";
import { IModelGalleryContext, IModelGalleryImage } from "./model";
import UploadImageDialog from "./UploadImageDialog";
import ModelGalleryPreviewDialog from "./PreviewDialog";

export const ModelGalleryContext = createContext<IModelGalleryContext | null>(
  null
);

export const ModelGalleryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [model, setModel] = useState<{ id: string } | null>(null);
  const [images, setImages] = useState<IModelGalleryImage[]>([]);
  const [currentImage, setCurrentImage] = useState<IModelGalleryImage | null>(
    null
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  return (
    <ModelGalleryContext.Provider
      value={{
        images,
        currentImage,
        galleryOpen,
        uploadDialogOpen,
        model,
        setModel,
        setImages,
        setCurrentImage,
        setGalleryOpen,
        setUploadDialogOpen,
        tooltipOpen,
        setTooltipOpen,
      }}
    >
      <UploadImageDialog />
      <ModelGalleryPreviewDialog />
      {children}
    </ModelGalleryContext.Provider>
  );
};
