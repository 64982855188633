import { useContext } from "react";
import ModelService from "../../services/ModelService";
import { Model } from "../../../../models/Model";
import { EmbeddedModelDetailsPageContext } from "./context";

export const useEmbeddedModelDetailsPage = () => {
  const ctx = useContext(EmbeddedModelDetailsPageContext);
  if (!ctx) {
    throw new Error(
      "useEmbeddedModelDetailsPage must be used within EmbeddedModelDetailsPageProvider"
    );
  }

  const loadModel = async (modelPublicKey: string) => {
    try {
      const response = await ModelService.getPublicModel(modelPublicKey);
      ctx.setModel(response?.data);
      return response?.data || null;
    } catch (error) {
      return null;
    }
  };

  const init = async (modelPublicKey: string): Promise<Model | null> => {
    return loadModel(modelPublicKey);
  };

  return {
    init,
    loadModel,
    ...ctx,
  };
};
