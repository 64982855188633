import { makeAutoObservable } from "mobx";

export interface IGlobalStore {
  redirectRoute: string;
  mobileNavOpen: boolean;
  uploadDialogOpen: boolean;
  showViewerAreaLoader: boolean;
  subscriptionBarVisible: boolean;
  openUploadDialog: () => void;
  closeUploadDialog: () => void;
  toggleMobileNav: (open: boolean) => void;
  setRedirectRoute: (path: string) => void;
  setSubscriptionBarVisibility: (isVisible: boolean) => void;
}

class GlobalStore implements IGlobalStore {
  redirectRoute: string = "";
  mobileNavOpen: boolean = false;
  subscriptionBarVisible: boolean = false;
  // Identifier if user request was already done
  uploadDialogOpen = false;
  showViewerAreaLoader = false;

  constructor() {
    makeAutoObservable(this);
  }

  setRedirectRoute(path: string) {
    this.redirectRoute = path;
  }

  toggleMobileNav(open: boolean) {
    this.mobileNavOpen = open;
  }

  setSubscriptionBarVisibility(isVisible: boolean) {
    this.subscriptionBarVisible = isVisible;
  }

  openUploadDialog() {
    this.uploadDialogOpen = true;
  }

  closeUploadDialog() {
    this.uploadDialogOpen = false;
  }
}

export default new GlobalStore();
