export interface INoImagesInfoProps {
  title?: string;
  description?: string;
}

const NoImagesInfo = ({
  title = "No images available",
  description = "Upload images to your case to share with others",
}: INoImagesInfoProps) => {
  return (
    <div className="flex flex-col items-center text-center justify-center gap-y-4">
      <img
        src="/images/no-images.png"
        alt="No images available"
        width={128}
        height={136}
      />
      <h6 className="text-base font-medium">{title}</h6>
      <h5 className="text-sm text-gray-500">{description}</h5>
    </div>
  );
};

export default NoImagesInfo;
