import React, { useState } from "react";
import Dialog from "../../shared/components/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import ModelService from "views/models/services/ModelService";
import { getResponseErrorTranslation } from "utils/globalUtils";
import Description from "../../shared/typography/Description";
import { IModelListItem } from "../pages/ModelListPage.tsx/ModelList/interface";
import useToast from "../../shared/hook/useToast";

export enum ArchiveDialogType {
  Archive = "archive",
  Unarchive = "unarchive",
}

interface IArchiveDialogContainerPropTypes {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  model: IModelListItem;
  type: ArchiveDialogType;
}

const isArchived = (type: ArchiveDialogType) =>
  type === ArchiveDialogType.Archive;

const ArchiveDialogContainer = ({
  open,
  onClose,
  onSuccess,
  model,
  type,
}: IArchiveDialogContainerPropTypes) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const archiveModel = () => {
    setLoading(true);
    ModelService.patchModel(model.id, { archived: !isArchived(type) })
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        toast.error(getResponseErrorTranslation(err));
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      title={t(isArchived(type) ? "reveal" : "hide")}
      open={open}
      onClose={onClose}
      confirmButtonProps={{
        loading,
        onClick: archiveModel,
      }}
    >
      <Description>
        {t(
          isArchived(type) ? "revealModelDescription" : "hideModelDescription"
        )}
      </Description>
    </Dialog>
  );
};

export default ArchiveDialogContainer;
