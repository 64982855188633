import { useEffect, useState } from "react";
import { EmbeddedModelDetailsPageContextProvider } from "./context";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useEmbeddedModelDetailsPage } from "./useEmbeddedDetailsPage";
import Viewer from "../../../viewer/Viewer";
import { ViewerAnnotationsProvider } from "../../../viewer/context/ViewerAnnotationsContext";
import useTextureManager from "../../containers/ModelViewer/hooks/useTextureManager";
import { ViewerService } from "../../../viewer/services/ViewerService";
import { ITypedModelType } from "../../../../models/Model";
import { STORE_NAMES } from "../../../../const/global";
import { inject, observer } from "mobx-react";
import { IViewerStore } from "../../../../stores/ViewerStore";

interface IMatchParams {
  publicKey: string;
}

interface IEmbeddedDetailsPagePropTypes
  extends RouteComponentProps<IMatchParams> {
  ViewerStore?: IViewerStore;
}

const EmbeddedDetailsPage = (props: IEmbeddedDetailsPagePropTypes) => {
  const { match, ViewerStore } = props;
  const { init, model } = useEmbeddedModelDetailsPage();
  const [texture] = useTextureManager({ ViewerStore });
  const [pointCloudLoaded, setPointCloudLoaded] = useState(false);
  const viewerService = new ViewerService();

  useEffect(() => {
    const initView = async () => {
      if (!match.params.publicKey) {
        return;
      }

      await init(match.params.publicKey);
    };

    initView();
  }, [match.params.publicKey]);

  const loadTextures = async () => {
    // Initial load of textures on start
    await texture.loadTextures(model, () => {
      texture.showTextures();
    });
  };

  const loadPointCloud = () => {
    if (model.assets[ITypedModelType.PointCloud]) {
      viewerService.loadPointCloud(
        model.assets[ITypedModelType.PointCloud],
        handlePointcloudLoaded
      );
      return;
    }

    // Old point cloud type
    viewerService.loadPointCloud(
      model.assets[ITypedModelType.PointCloud18],
      handlePointcloudLoaded
    );
  };

  const handlePointcloudLoaded = () => {
    setPointCloudLoaded(true);
    viewerService.fitToScreen();
  };

  const initViewer = () => {
    if (model) {
      loadPointCloud();
      loadTextures();
    }
  };

  if (!model) {
    return null;
  }

  return (
    <div className="viewer-wrapper relative w-full h-full">
      <Viewer
        model={model}
        onMeasurementsSave={() => {}}
        onLoad={initViewer}
        className="h-full w-full"
      />
    </div>
  );
};

const withContext = (Component: React.ComponentType) => {
  return (props: any) => (
    <EmbeddedModelDetailsPageContextProvider>
      <ViewerAnnotationsProvider>
        <Component {...props} />
      </ViewerAnnotationsProvider>
    </EmbeddedModelDetailsPageContextProvider>
  );
};

export default inject(STORE_NAMES.ViewerStore)(
  withContext(withRouter(observer(EmbeddedDetailsPage)))
);
