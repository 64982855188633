import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Box, { BoxAlignedCenter } from "../../../shared/components/Box";
import SessionFindingsLiveUpdateSwitch from "../../container/SessionFindingsLiveUpdateSwitch";
import breakpoint from "styled-components-breakpoint";
import LinkIcon from "../../../shared/Icons/LinkIcon";
import CopyButton from "views/shared/components/CopyButton";
import DateComponent from "../../../shared/components/Date";
import Button from "../../../shared/buttons/Button";
import { trimStringIfToLong } from "utils/stringUtils";
import Heading from "../../../shared/typography/Heading";
import { WindowUtils } from "../../../../utils/windowUtils";
import { IAppContext } from "../../../interfaces";
import { SessionPageViewTypes, STORE_NAMES } from "../../../../const/global";
import { inject, observer } from "mobx-react";
import { IGlobalStore } from "../../../../stores/GlobalStore";
import { ISessionViewerPageStore } from "../../../../stores/SessionViewerPageStore";
import ModelViewTypeButton from "../../../models/components/ModelViewTypeButton";
import { ModelStatus } from "../../../../models/Model";
import useToast from "../../../shared/hook/useToast";

interface ISessionTopBarPropTypes {
  GlobalStore?: IGlobalStore;
  SessionViewerPageStore?: ISessionViewerPageStore;
}

const ModelDetailsRow = styled(Box)`
  align-items: center;
  justify-content: space-between;
  padding: 0 ${(p: IAppContext) => p.theme.skye.sizes.medium};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  width: 100%;
`;
const SessionAddressWrapper = styled.div`
  margin: 0 ${(p: IAppContext) => p.theme.skye.sizes.average};
`;

const DesktopModelDetailsRow = styled(ModelDetailsRow)`
  height: 72px;
  display: none;

  ${breakpoint("md")`
    display: flex;
  `}
`;
const ModelDetailsLeftBox = styled.div``;
const ModelDetailsRightBox = styled(BoxAlignedCenter)`
  .btn {
    margin-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
  }
`;
const SessionCreationDate = styled.h6`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
  margin-bottom: 0;
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.radius};
`;
const HeadingWrapper = styled(Box)`
  align-items: center;

  .badge {
    display: none;
    margin-left: ${(p: IAppContext) => p.theme.skye.sizes.medium};

    ${breakpoint("md")`
      display: flex;
    `}
  }
`;
const MobileDetailsButtonsRow = styled(BoxAlignedCenter)`
  width: 100%;
`;
const MobileModelDetailsRow = styled(ModelDetailsRow)`
  padding-top: ${(p: IAppContext) => p.theme.skye.sizes.average};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${SessionAddressWrapper} {
    margin: ${(p: IAppContext) => p.theme.skye.sizes.radius} 0
      ${(p: IAppContext) => p.theme.skye.sizes.small} 0;
  }

  .heading {
    font-size: ${(p: IAppContext) => p.theme.skye.sizes.average};
    margin-bottom: 0;
  }

  .btn {
    margin: ${(p: IAppContext) => p.theme.skye.sizes.average} 0;

    &:first-of-type {
      margin-right: ${(p: IAppContext) => p.theme.skye.sizes.average};
    }
  }

  ${breakpoint("md")`
    display: none;
  `}
`;

const SessionTopBar = ({
  GlobalStore,
  SessionViewerPageStore,
}: ISessionTopBarPropTypes) => {
  const { t } = useTranslation();
  const model = SessionViewerPageStore.sessionModel;
  const { session, viewType } = SessionViewerPageStore;
  const isMapView = SessionPageViewTypes.Map === viewType;
  const toast = useToast();

  const toggleView = (): void => {
    if (isMapView && model && model.status === ModelStatus.Finished) {
      SessionViewerPageStore.setViewType(SessionPageViewTypes.Model);
      return;
    }
    SessionViewerPageStore.setViewType(SessionPageViewTypes.Map);
  };

  const copySessionAddress = () => {
    WindowUtils.copyToClipboard(session.publicId);
    toast.success(t("copied"));
  };

  return (
    <>
      <DesktopModelDetailsRow>
        <ModelDetailsLeftBox>
          <HeadingWrapper>
            <Heading>
              {session ? trimStringIfToLong(session.name, 60) : ""}
            </Heading>
            <SessionAddressWrapper>
              <CopyButton copyContent={session.publicId}>
                {t("copySessionAddress")}
              </CopyButton>
            </SessionAddressWrapper>
          </HeadingWrapper>
          <SessionCreationDate>
            <DateComponent>{session.createdAt}</DateComponent>
          </SessionCreationDate>
        </ModelDetailsLeftBox>
        <ModelDetailsRightBox>
          <SessionFindingsLiveUpdateSwitch />
          <ModelViewTypeButton
            onClick={toggleView}
            currentView={viewType as any}
          />
        </ModelDetailsRightBox>
      </DesktopModelDetailsRow>
      <MobileModelDetailsRow>
        <Heading>{session ? trimStringIfToLong(session.name, 30) : ""}</Heading>
        <MobileDetailsButtonsRow>
          <Button
            fullSize
            endIcon={<LinkIcon />}
            onClick={copySessionAddress}
            type="light"
          >
            {t("copyAddress")}
          </Button>
          <ModelViewTypeButton
            onClick={toggleView}
            currentView={viewType as any}
          />
        </MobileDetailsButtonsRow>
      </MobileModelDetailsRow>
    </>
  );
};

export default inject(
  STORE_NAMES.SessionViewerPageStore,
  STORE_NAMES.GlobalStore
)(observer(SessionTopBar));
