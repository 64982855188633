import Button from "../../shared/buttons/Button";
import GalleryIcon from "../../shared/Icons/GalleryIcon";
import InformationTooltip from "../../shared/components/InformationTooltip/InformationTooltip";
import TooltipContent from "../components/ModelTooltipGallery/TooltipContent";
import { useModelGallery } from "./Gallery/useGallery";
import { useEffect } from "react";
import ModelAssetService from "../services/ModelAssetService";
import { INoImagesInfoProps } from "../components/ModelTooltipGallery/NoImagesInfo";

const ModelGalleryButtonContainer = ({
  modelId,
  allowUpload,
  noImagesInfoProps,
}: {
  modelId: string;
  allowUpload?: boolean;
  noImagesInfoProps?: INoImagesInfoProps;
}) => {
  const {
    galleryOpen,
    setGalleryOpen,
    setUploadDialogOpen,
    setImages,
    images,
    setModel,
    setCurrentImage,
    setTooltipOpen,
    tooltipOpen,
  } = useModelGallery();

  const handleGalleryButtonClick = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const fetchImages = async () => {
    const response = await ModelAssetService.getModelAssets(modelId);
    const images = response.data.map((asset) => {
      return {
        id: asset.id,
        src: asset.url,
        alt: asset.originalName,
        title: asset.originalName,
      };
    });
    setImages(images);
  };

  useEffect(() => {
    setModel({ id: modelId });
    fetchImages();
  }, [modelId]);

  return (
    <>
      <InformationTooltip
        open={tooltipOpen}
        content={
          <TooltipContent
            title="Case Gallery"
            images={images}
            onImageClick={(image) => {
              setCurrentImage(image);
              setGalleryOpen(true);
              setTooltipOpen(false);
            }}
            uploadButtonProps={
              allowUpload
                ? {
                    children: "Upload",
                    onClick: () => {
                      setUploadDialogOpen(true);
                      setTooltipOpen(false);
                    },
                  }
                : undefined
            }
            noImagesInfoProps={noImagesInfoProps}
            showGallery={true}
            showEmptyGallery={images.length === 0}
          />
        }
      >
        <Button
          id="model-gallery-button"
          className="gallery-btn right-row-btn"
          endIcon={<GalleryIcon />}
          type="light"
          onClick={() => handleGalleryButtonClick()}
        />
      </InformationTooltip>
    </>
  );
};

export default ModelGalleryButtonContainer;
