import { useTranslation } from "react-i18next";
import BasePage from "../../../shared/layouts/BasePage";
import Title from "../../../shared/typography/Title";
import { useEffect } from "react";
import { WindowUtils } from "../../../../utils/windowUtils";
import useCurrentUser from "../../../account/hooks/useCurrentUser";
import { SubscriptionType } from "../../../payments/payments.model";
import { useSubscriptionPaymentDialog } from "../../../models/payments/useSubscriptionPaymentDialog";
import SubscriptionInformationRow from "../../../shared/components/AdvancedInformationRow";
import Button from "../../../shared/buttons/Button";
import { HUGGINGFACE_ROUTES } from "../../../routes";

const SCRIPT_CLASS = "gradio-app-script";

const ThreeDAI = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const subscription = useSubscriptionPaymentDialog();
  const pricingDialog = subscription.content;

  // For now, we are showing the content to all users
  const showContent = true;

  useEffect(() => {
    WindowUtils.loadScript(
      "https://gradio.s3-us-west-2.amazonaws.com/4.44.1/gradio.js",
      "module",
      "gradio-app-script"
    );

    return () => {
      const script = document.querySelector(`script.${SCRIPT_CLASS}`);
      if (script) {
        script.remove();
      }
    };
  }, []);

  return (
    <BasePage backgroundColor="white">
      <Title>3D AI</Title>
      {showContent ? (
        <div className="mt-8 w-full h-full">
          <gradio-app src={HUGGINGFACE_ROUTES.threeDProcessing} />
        </div>
      ) : (
        <div className="w-full">
          {pricingDialog}
          <SubscriptionInformationRow
            color="primary"
            iconColor="primary"
            icon="crown"
            primaryText="Get access to Interior AI"
            secondaryText="Redesign your interior space in seconds. Subscribe to Lite to access Interior AI."
            button={
              <Button
                className="w-max"
                color="primary"
                size="small"
                onClick={subscription.openDialog}
              >
                {t("subscription.subscribeNowBtn")}
              </Button>
            }
          ></SubscriptionInformationRow>
        </div>
      )}
    </BasePage>
  );
};

export default ThreeDAI;
