import httpService, { IResponse } from "services/HttpService";
import modelEnv from "../model.env";

interface ModelAsset {
  id: string;
  originalName: string;
  url: string;
}

class ModelAssetService {
  static async getModelAssets(
    modelId: string
  ): Promise<IResponse<ModelAsset[]>> {
    const url = `${modelEnv.apiUrl}/${modelId}/assets`;
    const response = await httpService.get(url);
    return response;
  }

  static async addModelAsset(modelId: string, file: File) {
    const url = `${modelEnv.apiUrl}/${modelId}/assets`;
    const formData = new FormData();
    formData.append("file", file);
    const response = await httpService.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }

  static async deleteModelAsset(modelId: string, assetId: string) {
    const url = `${modelEnv.apiUrl}/${modelId}/assets/${assetId}`;
    const response = await httpService.delete(url);
    return response;
  }
}

export default ModelAssetService;
