import { useTranslation } from "react-i18next";
import { useSubscriptionPaymentDialog } from "../../../../models/payments/useSubscriptionPaymentDialog";
import SubscriptionInformationRow from "../../AdvancedInformationRow";
import Button from "../../../buttons/Button";
import useCanUpload from "../../../../models/hooks/useCanUpload";
import { useEffect } from "react";

const ExpiredSubscriptionInfo = () => {
  const subscription = useSubscriptionPaymentDialog();
  const pricingDialog = subscription.content;
  const canUploadStatus = useCanUpload();
  const { t } = useTranslation();

  useEffect(() => {
    if (!canUploadStatus.loaded) {
      canUploadStatus.fetch();
    }
  }, [canUploadStatus]);

  if (!canUploadStatus.loaded) {
    return null;
  }

  if (!canUploadStatus.canUpload) {
    return (
      <div className="subscription-info">
        {pricingDialog}
        <SubscriptionInformationRow
          color="danger"
          iconColor="danger"
          primaryText={t("Maximum amount of models reached!")}
          secondaryText={t(
            "Upgrade now to unlock more capacity and continue creating without limits or delete some of your models!"
          )}
          button={
            <Button
              className="w-max"
              color="primary"
              size="small"
              onClick={subscription.openDialog}
            >
              {t("subscription.subscribeNowBtn")}
            </Button>
          }
        ></SubscriptionInformationRow>
      </div>
    );
  }

  return (
    <div className="subscription-info">
      {pricingDialog}
      <SubscriptionInformationRow
        iconColor="primary"
        primaryText={t("subscription.upgradeInfoTitle")}
        secondaryText={t("subscription.upgradeInfoDescription")}
        button={
          <Button
            className="w-max"
            color="primary"
            size="small"
            onClick={subscription.openDialog}
          >
            {t("subscription.subscribeNowBtn")}
          </Button>
        }
      ></SubscriptionInformationRow>
    </div>
  );
};

export default ExpiredSubscriptionInfo;
