import { createContext, useState } from "react";
import { Model } from "../../../../models/Model";
import { WindowUtils } from "../../../../utils/windowUtils";
import {
  MODEL_DETAILS_PAGE_TAB_PATH_KEY,
  MODEL_PAGE_VIEW_TYPE_PARAM_NAME,
  ModelDetailsPageTabKeys,
  ModelPageViewTypes,
} from "../../../../const/global";

interface ModelDetailsPageContextProps {
  model: Model | null;
  setModel: (model: Model) => void;
  viewType: ModelPageViewTypes;
  setViewType: (viewType: ModelPageViewTypes) => void;
  currentTabKey: ModelDetailsPageTabKeys;
  setCurrentTabKey: (currentTabKey: ModelDetailsPageTabKeys) => void;
}

const getDefaultPageTab = () => {
  return WindowUtils.getQueryParam(
    MODEL_PAGE_VIEW_TYPE_PARAM_NAME,
    ModelPageViewTypes.Map
  );
};

const getDefaultTabKey = () => {
  return WindowUtils.getQueryParam(
    MODEL_DETAILS_PAGE_TAB_PATH_KEY,
    ModelDetailsPageTabKeys.General
  );
};

export const ModelDetailsPageContext =
  createContext<ModelDetailsPageContextProps>(null);

export const ModelDetailsPageContextProvider = ({ children }: any) => {
  const [model, setModel] = useState<Model | null>(null);
  const [viewType, setViewType] = useState(getDefaultPageTab());
  const [currentTabKey, setCurrentTabKey] = useState<ModelDetailsPageTabKeys>(
    getDefaultTabKey()
  );

  return (
    <ModelDetailsPageContext.Provider
      value={{
        model,
        setModel,
        viewType,
        setViewType,
        currentTabKey,
        setCurrentTabKey,
      }}
    >
      {children}
    </ModelDetailsPageContext.Provider>
  );
};
